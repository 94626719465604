export const mapLabelIcon = (iconCategory) => {
  switch (iconCategory) {
    case "Trekking":
      return "map-trekking";
    case "Beach":
      return "map-beach";
    case "Signature":
      return "map-signature";
    case "Diving":
      return "map-diving";
    case "Boat":
      return "phinisi";
    default:
      return null;
  }
};
