import React from "react";
import Icon from "components/CommonIcon/Icon";
import { BuildingIcon } from "components/MultiColorIcon/BuildingIcon";
import cx from "classnames";
import "./style.less";

const MapMarker = ({ name, icon, selectCategorisedTab, category, type }) => {
  return (
    <div
      onClick={(e) =>
        type === "feature" ? null : selectCategorisedTab(e.target, category)
      }
      className={cx({
        "relative bg-white shadow-marker p-4": type !== "feature",
      })}>
      <div
        className={cx({
          "marker-tip absolute bottom-0 top-32 transform left-1/2 -translate-x-1/2 -translate-y-1/2":
            type !== "feature",
        })}></div>
      <div
        className={cx("flex items-center h-full w-full", {
          "cursor-pointer": type !== "feature",
        })}>
        {category === "Building" ? (
          <BuildingIcon />
        ) : (
          <Icon
            name={icon}
            fontSize={category === "Boat" ? "text-68" : "text-14"}
          />
        )}
        <h2
          className={cx("text-center ml-4", {
            "text-14-16 font-cormorant font-normal text-gray-text":
              type === "feature",
            "text-14-24 font-mulish font-bold text-black": type !== "feature",
          })}>
          {name}
        </h2>
      </div>
    </div>
  );
};

export default MapMarker;
