import React from "react";
import { Grid } from "./Design/Grid/Grid";

/* columnsOnDesktop is not equal to number of cards to render,
 please think of columns from the perspective of grid layout */

export const Listing = ({ title, children, columnsOnDesktop }) => {
  const className =
    columnsOnDesktop === 2
      ? "col-span-6"
      : columnsOnDesktop === 3
      ? "col-span-4"
      : "col-span-12";

  return (
    <>
      {title && (
        <>
          <div class="font-mulish font-bold uppercase text-12-18 text-gold tracking-8 mb-6">
            <h3>{title}</h3>
          </div>
          <hr class="border-t border-gray-light mb-16" />
        </>
      )}
      <Grid>
        {React.Children.map(children, (child) => {
          return (
            <div key={child.key} className={className}>
              {child}
            </div>
          );
        })}
      </Grid>
    </>
  );
};

Listing.defaultProps = {
  columnsOnDesktop: 2,
};
