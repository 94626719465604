import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Icon from "components/CommonIcon/Icon";
import { ArtDirectedImage } from "components/Design/ArtDirectedImage";
import ConditionalLink from "components/ConditionalLink";
import MarkDown from "../MarkDown";

export const Highlight = ({ item }) => {
  const { t } = useTranslation("homepage");
  const { title, slug, cta_link } = item;

  return (
    <ConditionalLink
      link={slug || cta_link}
      className="relative block w-320 shrink-0 z-1"
      data-testid={title}>
      <ArtDirectedImage
        image={item}
        alt={title}
        className="h-full w-full"
        imgClassName="rounded-sm"
      />
      <div className="absolute bottom-0 inset-x-0 px-12 pb-12 z-1 text-white bg-gradient-overlay">
        <span className="text-24-32 font-semibold">{title}</span>
        {Boolean(slug || cta_link) && (
          <span className="font-mulish font-semibold uppercase text-12-22 flex items-center tracking-16 mt-8">
            {t("See Detail")}
            <span className="ml-5">
              <Icon
                name="right-arrow-thin"
                fontSize="text-12"
                color="text-white"
              />
            </span>
          </span>
        )}
      </div>
    </ConditionalLink>
  );
};

export const ListingCard = ({ image, caption, title, description }) => {
  return (
    <div>
      <ArtDirectedImage image={image} alt={title} imgClassName="rounded-sm" />
      {caption && (
        <p className="font-mulish text-14-24 text-gray-text pb-20 pt-8">
          {caption}
        </p>
      )}
      {title && (
        <h4 className="font-cormorant text-h4 text-gray-text font-semibold pt-12 pb-4 lg:py-4 lg:pt-20">
          {title}
        </h4>
      )}
      {description && (
        <div class="font-mulish text-14-24 line-clamp-4 text-gray-text whitespace-pre-wrap">
          <MarkDown>{description}</MarkDown>
        </div>
      )}
    </div>
  );
};
