import React, { useState, useRef } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "components/Layout/Layout";
import LakodiaDestinationMapSection from "components/LakodiaDestinationMap/LakodiaDestinationMap";
import { CRUISE_INQUIRY } from "store/constants/support";
import PageContext from "contexts/PageContext";
import { DESTINATION_TAB } from "dictionaries/destinationTab";
import { gsap } from "gsap";
import { generateSupportURL } from "utils/generateSupportURL";
import { transformObject } from "utils/transformv4";
import { Container } from "components/Design/Grid/Grid";
import { mapLabelIcon } from "utils/mapLabelIcon";
import BackToTop from "components/Design/BackToTop/BackToTop";
import { Listing } from "components/Listing";
import { ListingCard } from "components/Design/Card/Highlight";
import { Tab } from "components/Design/Tabs";
import PageSection from "components/Design/PageSection/PageSection";
import { HashTabs } from "components/HashTabs";

const DestinationLanding = ({ data, location }) => {
  const { t } = useTranslation("homepage");
  const [tabActive, setTabActive] = useState(
    location.hash || location.state?.tabActive
  );
  const markerRef = useRef(null);
  const cardPositionRef = useRef({});
  const scrollToBannerRef = useRef(null);
  const {
    cms,
    allDataJson: {
      nodes: [features],
    },
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    hotelsByLocale: [hotel],
    page: [{ seo_tags, sections }],
    supportDrawer: [supportDrawer],
    sections: [destinations],
  } = transformObject(cms);

  const dataContact = {
    url: generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY),
    label: t("Contact Us"),
  };

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  const introSection = sections.find(
    (section) => section.name === "LKD_Destinations_Intro"
  );

  const signatureDestination = sections.find(
    (section) => section.name === "LKD_Destination_Signature"
  );

  const trekkingDestination = sections.find(
    (section) => section.name === "LKD_Destination_Trekking"
  );

  const beachDestination = sections.find(
    (section) => section.name === "LKD_Destination_Beach"
  );

  //scroll to Tab section
  const scrollToCardDetails = (targetRef, targetValue) => {
    gsap.to(window, {
      duration: 2,
      scrollTo: targetRef.current[targetValue].offsetTop - 130,
      ease: "power4.out",
    });
  };

  //invoked when clicked on the marker
  const callSelectedTab = (categoryRef) => {
    setTabActive(categoryRef);
  };

  // when marker is clicked
  const selectCategorisedTab = (target, category) => {
    const targetValue = target.textContent;
    markerRef.current = DESTINATION_TAB[category];
    callSelectedTab(markerRef.current);
    /*Added setTimeout, because the tab contents take some time to be available in DOM
     and then it invokes the scrollToCardDetails().
    */
    setTimeout(() => {
      scrollToCardDetails(cardPositionRef, targetValue);
    });
  };

  return (
    <PageContext.Provider value={{ tabActive: tabActive }}>
      <Layout
        destination={t("Lakodia")}
        seoTitle={t("Ayana Lako Di'a")}
        seoTags={seo_tags}
        showBanner={true}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Lako di'a")}
        supportDrawer={supportDrawerData}
        destinationCards={destinations.images}
        navigations={navigations}
        hotel={hotel}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}
        navElement="Room">
        <LakodiaDestinationMapSection
          features={features}
          selectCategorisedTab={selectCategorisedTab}
          scrollToBannerRef={scrollToBannerRef}
        />

        <div className="pt-40 pb-40 md:pb-20 bg-white">
          <PageSection
            className="pt-40"
            subHeading={introSection.title}
            description={
              <span className="text-24-32">{introSection.description}</span>
            }
          />
          <Container>
            <HashTabs hashes={["signature", "trekking", "beach"]} centered>
              <Tab
                title={signatureDestination.title}
                icon={mapLabelIcon(signatureDestination.title)}>
                <>
                  <Listing columnsOnDesktop={2}>
                    {signatureDestination.cards.map(
                      ({ mobile, desktop, title, description, id }) => (
                        <ListingCard
                          key={id}
                          image={{ mobile, desktop }}
                          title={title}
                          description={description}
                        />
                      )
                    )}
                  </Listing>

                  <BackToTop
                    contentRef={scrollToBannerRef}
                    visibilityHeight={530}
                  />
                </>
              </Tab>
              <Tab
                title={trekkingDestination.title}
                icon={mapLabelIcon(trekkingDestination.title)}>
                <>
                  <Listing columnsOnDesktop={2}>
                    {trekkingDestination.cards.map(
                      ({ id, mobile, desktop, title, description }) => (
                        <ListingCard
                          key={id}
                          image={{ mobile, desktop }}
                          title={title}
                          description={description}
                        />
                      )
                    )}
                  </Listing>
                  <BackToTop
                    contentRef={scrollToBannerRef}
                    visibilityHeight={530}
                  />
                </>
              </Tab>
              <Tab
                title={beachDestination.title}
                icon={mapLabelIcon(beachDestination.title)}>
                <>
                  <Listing columnsOnDesktop={2}>
                    {beachDestination.cards.map(
                      ({ mobile, desktop, title, description, id }) => (
                        <ListingCard
                          key={id}
                          image={{ mobile, desktop }}
                          title={title}
                          description={description}
                        />
                      )
                    )}
                  </Listing>
                  <BackToTop
                    contentRef={scrollToBannerRef}
                    visibilityHeight={530}
                  />
                </>
              </Tab>
            </HashTabs>
          </Container>
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query LakodiaDestinationLanding($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allDataJson {
      nodes {
        features {
          type
          geometry {
            coordinates
          }
          properties {
            place
            category
          }
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      sections(filters: { name: { eq: "LKD_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(filters: { name: { eq: "LKD_Support_Drawer" } }) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { id: 5 }) {
        data {
          id
          attributes {
            name
            email
            phone
            location
            whatsapp_contact
            custom_whatsapp_message
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            ...CmsHotelLogo_v4
          }
        }
      }
      page: pagesByLocale(
        language: $language
        where: { name: "LKD_Destination" }
      ) {
        data {
          id
          attributes {
            name
            seo_tags {
              id
              meta_title
              meta_description
            }
            sections {
              data {
                id
                attributes {
                  ...CmsSection_v4
                  cards {
                    ...CmsCardFullWidth_v4
                  }
                  images {
                    ...CmsImageFullWidth_v4
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DestinationLanding;
