import React from "react";

export const BuildingIcon = () => {
  return (
    <span className="ay-icon-akr text-24">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
      <span className="path11"></span>
    </span>
  );
};
