import React, { useMemo, useState } from "react";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { renderForMobile } from "utils/renderForMobile";
import MapMarker from "components/Design/Marker";
import { mapLabelIcon } from "utils/mapLabelIcon";
import { mapStyleLink } from "store/constants/mapStyleLink";
import "./LakodiaDestinationMap.less";

const LakodiaDestinationMapSection = ({
  features,
  selectCategorisedTab,
  scrollToBannerRef,
}) => {
  const breakpoints = useBreakpoint();

  //Navigation Control
  const navigationControlStyle = {
    right: 10,
    bottom: 10,
    zIndex: 1,
  };

  // Default Viewport
  const [viewPort, setViewPort] = useState({
    latitude: -8.576261,
    longitude: 119.683667,
    zoom: 9.68,
    width: "100%",
    height: renderForMobile(breakpoints) ? "480px" : "580px",
  });

  // Only rerender when the map data changes
  const markers = useMemo(
    () =>
      features.features.map((data) => (
        <Marker
          key={data.properties.place}
          latitude={data.geometry.coordinates[1]}
          longitude={data.geometry.coordinates[0]}>
          <MapMarker
            selectCategorisedTab={selectCategorisedTab}
            category={data.properties.category}
            type={data.type}
            name={data.properties.place}
            icon={mapLabelIcon(data.properties.category)}
          />
        </Marker>
      )),
    [features.features, selectCategorisedTab]
  );

  const _onViewportChange = (viewPort) => {
    setViewPort({ ...viewPort });
  };

  return (
    <div ref={scrollToBannerRef}>
      <ReactMapGL
        {...viewPort}
        scrollZoom={renderForMobile(breakpoints) ? false : true} // disables scroll-in & out zoom in mobile view
        touchAction={renderForMobile(breakpoints) ? "pan-y" : "none"} // allow vertical scrolling on the map
        attributionControl={false}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
        mapStyle={mapStyleLink}
        onViewportChange={_onViewportChange}>
        <NavigationControl style={navigationControlStyle} showCompass={false} />
        {markers}
      </ReactMapGL>
    </div>
  );
};

export default LakodiaDestinationMapSection;
