import React, { useEffect, useState } from "react";
import cx from "classnames";

// Added "dist" because test case was failing
//Link -->  https://githubmemory.com/repo/greensock/GSAP/issues/468

import Icon from "components/CommonIcon/Icon";
import "./index.less";
import { useViewportScroll } from "framer-motion";

const BackToTop = ({ contentRef, visibilityHeight, minHeight }) => {
  const topAdjustment = 150; // space for top navbar menu
  const [showScroll, setShowScroll] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const scrollTop = () => {
    if (showScroll) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (contentRef?.current) {
      setContentHeight(contentRef?.current?.offsetHeight);
    }
  }, [contentRef]);

  const { scrollY } = useViewportScroll();

  scrollY.onChange((offsetY) => {
    const contentTop = contentRef?.current?.offsetTop - topAdjustment;
    if (contentHeight > minHeight || contentHeight < minHeight) {
      if (!showScroll && offsetY > contentTop + visibilityHeight) {
        setShowScroll(true);
      } else if (showScroll && offsetY <= contentTop + visibilityHeight) {
        setShowScroll(false);
      }
    }
  });

  return (
    <button
      className={cx(
        "back-to-top ease-out duration-500  transition-all",
        { "opacity-100 visible": showScroll },
        { "opacity-0 invisible": !showScroll }
      )}
      onClick={scrollTop}>
      <span className="transform rotate-90 flex justify-center w-32 h-32">
        <Icon name="left-arrow-thin" />
      </span>
    </button>
  );
};

BackToTop.defaultProps = {
  visibilityHeight: 300,
  minHeight: 500,
  contentRef: null,
};

export default BackToTop;
