import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { Tabs as DesignTabs } from "./Design/Tabs";

// This is not added to storybook as coponents using @reach/router is breaking storybook build
export const HashTabs = ({ onSelectHandler, hashes, ...props }) => {
  const { hash } = useLocation();
  const [selectedIndex, selectIndex] = useState();

  const onSelect = (index) => {
    onSelectHandler(index);
    selectIndex(index);
    window.history.replaceState(null, "", `#${hashes[index]}`);
  };

  useEffect(() => {
    if (hash) {
      const index = hashes.indexOf(hash.replace("#", ""));
      if (index > 0) {
        selectIndex(index);
      }
    }
  }, [hash, hashes]);

  return (
    <DesignTabs
      {...props}
      selectedIndex={selectedIndex}
      onSelectHandler={onSelect}
    />
  );
};

HashTabs.defaultProps = {
  onSelectHandler: () => {},
};
